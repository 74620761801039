import React from 'react';
import RegButton from "../ui/RegButton/RegButton";

import styles from "./Start.module.scss"

import {motion} from "framer-motion"


import MainMishaImage from "../../assets/images/main-image.png"

const Start = () => {
  return (
    <div className={styles.start}>
      <div className={styles.columns}>
        <div className={styles.textColumn}>
          <div className={styles.textWrapper}>
            <motion.h1 className={styles.title} initial={{x: -1000}} transition={{duration: .5}} animate={{
              x: 0,
            }}>
              <span className={styles.forum}>Форум:</span> <br/>"Цифровизация и оптимизация HR-процессов"
            </motion.h1>

            <motion.p className={styles.text} initial={{y: 100}} transition={{duration: .7}} animate={{y: 0}}>
              Своими кейсами поделятся HR-директора, IT-руководители и эксперты в области повышения производительности
              труда и оптимизации структуры компании.
            </motion.p>

            <br/>
            <br/>

            <motion.p className={styles.date}>
              29 ноября 11:00
            </motion.p>

            <motion.p className={styles.place}>г. Уфа, Sheraton Plaza,<br/> ул. Цюрупы д. 7, конференц-зал "Националь"
            </motion.p>

            <div className={styles.btnWrapper}>
              <RegButton className={styles.btn}/>
            </div>
          </div>
        </div>
        <div className={styles.widgetColumn}>
          <motion.div className={styles.widget} initial={{x: 1000}} transition={{duration: .7}} animate={{x: 0}}>
            <div className={styles.content}>
              <img src={MainMishaImage} alt="миша" className={styles.misha}/>

              <p className={styles.price}>
                БЕСПЛАТНО
              </p>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  )
    ;
};

export default Start;