import React from 'react';
import SectionTitle from "../ui/SectionTitle/SectionTitle";
import PartnerCard from "./PartnerCard/PartnerCard";

import styles from "./Partners.module.scss"

interface PartnersProps {
  partners: {
    logo: any;
    link: string;
  }[]
}

const Partners = ({partners}: PartnersProps) => {
  const chunkedItems = Array.from({length: Math.ceil(partners.length / 3)}, (_, index) =>
    partners.slice(index * 3, index * 3 + 3)
  );
  return (
    <div className={styles.partners}>
      <SectionTitle title={"Партнеры"}/>

      {chunkedItems.map((item, chunkIndex) => (
        <div className={styles.columns} key={chunkIndex}>
          {item.map((partner, index) => (
            <div className={styles.column} key={index}><PartnerCard link={partner.link} logo={partner.logo}
                                                                    key={index}/></div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Partners;