import React from 'react';
import Partners from "./components/Partners/Partners";
import Program from "./components/Program/Program";
import Speakers from "./components/Speakers/Speakers";
import Start from "./components/Start/Start";
import Footer from "./components/ui/Footer/Footer";
import Header from "./components/ui/Header/Header";
import SectionLayout from "./layouts/SectionLayout/SectionLayout";
import StartSectionLayout from "./layouts/StartSectionLayout/StartSectionLayout";
import {PartnersContent} from "./lib/PartnersContent";
import {SpeakersData} from "./lib/SpeakersData";


function App() {
  return (
    <div className="App">
      <Header/>

      <StartSectionLayout id={"reg"}>
        <Start/>
      </StartSectionLayout>

      <SectionLayout id={"program"}>
        <Program/>
      </SectionLayout>

      <SectionLayout id={"speakers"}>
        <Speakers speakers={SpeakersData}/>
      </SectionLayout>

      <SectionLayout id={"partners"}>
        <Partners partners={PartnersContent}/>
      </SectionLayout>

      <SectionLayout>
        <Footer/>
      </SectionLayout>
    </div>
  );
}

export default App;
