import React from 'react';

import styles from "./RegButton.module.scss"

import RightArrowIcon from "../../../assets/images/icons/right-arrow.svg"

interface RegBtnProps {
  className?: string;
}

const RegButton = ({className}: RegBtnProps) => {
  return (
    <button className={className ? styles.regBtn + " " + className : styles.regBtn}
            onClick={() => window.location.href = "https://online.sbis.ru/event/HR-forum"}>
      <p className={styles.btnText}>Регистрация</p>
      <img src={RightArrowIcon} alt="принять участие" className={styles.icon}/>
    </button>
  );
};

export default RegButton;