import MinRB from "../assets/images/logos/minrb.jpg"
import FamMin from "../assets/images/logos/min-family.png"
import RfJob from "../assets/images/logos/rabota-rf.png"

export const PartnersContent = [
  {
    logo: MinRB,
    link: "https://biznestur.bashkortostan.ru/"
  }, {
    logo: FamMin,
    link: "https://mintrud.bashkortostan.ru/"
  }, {
    logo: RfJob,
    link: "https://trudvsem.ru/"
  },
]