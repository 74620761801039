import React, {useState} from 'react';

import styles from './Header.module.scss'

import {NavItems} from './NavItems'
import MenuIcon from '@mui/icons-material/Menu';

import {motion} from "framer-motion"

import CloseIcon from '@mui/icons-material/Close'

import SabyHrmLogo from "../../../assets/images/logos/saby-hrm.png"

const Header = () => {
  const [isBurgerOpened, setIsBurgerOpened] = useState(false)

  return (
    <motion.header className={styles.header} initial={{y: -100}} animate={{y: 0}} transition={{duration: .5}}>
      <div className={styles.headerWrapper}>
        {/*<a href="/"><img src={logoImage} alt="Логотип" className={styles.logo}/></a>*/}

        <a href="/" className={styles.logo}><img src={SabyHrmLogo} alt="логотип"/></a>
        <nav>
          <ul className={styles.navItems}>
            {NavItems.map((item, index) => (
              <li className={styles.navItem}>
                <a href={item.linkTo} key={index}>{item.text}</a>
              </li>
            ))}
          </ul>
        </nav>

        <div className={styles.headerContacts}>
          <a href='tel:79871007010'>+7 (987) 100-70-10</a>
        </div>

        <div className={isBurgerOpened ? styles.hideIcon : styles.burgerIcon}>
          <MenuIcon onClick={() => setIsBurgerOpened(true)}/>
        </div>
      </div>

      {isBurgerOpened &&
          <div className={styles.burger}>
              <div className={isBurgerOpened ? styles.displayCloseIcon : styles.hideIcon}>
                  <CloseIcon onClick={() => setIsBurgerOpened(false)}/>
              </div>

              <nav className={styles.burgerNav}>
                  <ul className={styles.navItems}>
                    {NavItems.map((item, index) => (
                      <li className={styles.navItem}>
                        <a href={item.linkTo} key={index}>{item.text}</a>
                      </li>
                    ))}
                  </ul>
              </nav>
          </div>
      }
    </motion.header>
  );
};

export default Header;