import React from 'react';
import {NavItems} from "../Header/NavItems";

import styles from "./Footer.module.scss"

import CVLogo from "../../../assets/images/logos/logo.svg"

import TelegramIcon from "../../../assets/images/icons/tg.svg"
import VkIcon from "../../../assets/images/icons/vk.svg"
import WHIcon from "../../../assets/images/icons/whatsapp.svg"

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.columns}>
        <div className={styles.column}>
          <div className={styles.aboutCompany}>

            <a href="https://www.saby.pro">
              <img src={CVLogo} alt="Центр Внедрения СБИС Уфа" className={styles.logo}/>
            </a>

            <div className={styles.socials}>
              <a href="https://t.me/+79659218817" className={styles.socialItem}>
                <img src={TelegramIcon} alt="Телеграм" className={styles.socialIcon}/>
              </a>

              <a href="https://api.whatsapp.com/send/?phone=79659218817&text&type=phone_number&app_absent=0" className={styles.socialItem}>
                <img src={WHIcon} alt="Вацап" className={styles.socialIcon}/>
              </a>

              <a href="https://vk.com/sbis_pro" className={styles.socialItem}>
                <img src={VkIcon} alt="Вконтакте" className={styles.socialIcon}/>
              </a>
            </div>
          </div>
        </div>

        <div className={styles.column}>
          <div className={styles.menu}>
            <ul>
              {NavItems.map((item, index) => (
                <li key={index}><a href={item.linkTo} className={styles.menuItem}>{item.text}</a></li>
              ))}
            </ul>
          </div>
        </div>

        <div className={styles.column}>
          <div className={styles.org}>
            <h2 className={styles.title}>Организатор:</h2>

            <div className={styles.credentials}>
              <h2 className={styles.credential}>ООО "ЦЕНТР ВНЕДРЕНИЯ "СБИС" УФА"</h2>
              <h2 className={styles.credential}>ИНН: 0278969207</h2>
              <h2 className={styles.credential}>ОГРН: 1210200041193</h2>
            </div>

            <div className={styles.orgContacts}>
              <a href="call://79061006010" className={styles.orgContact}>+7 (906) 100-60-10</a>
              <a href="mailto:ufa@sbis.pro" className={styles.orgContact}>ufa@sbis.pro</a>
              <a href="https://saby.pro" className={styles.orgContact}>saby.pro</a>
            </div>
            <p className={styles.remark}>©️ 2024, все права защищены</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;