import EfimovImage from "../assets/images/speakers/efimov-mikhail.png"
import KuklinImage from "../assets/images/speakers/kuklin-alex.png"
import AkhyamovImage from "../assets/images/speakers/akhyamov-ridan.png"
import MelnikovImage from "../assets/images/speakers/melnikov-yuriy.png"
import KamalovaImage from "../assets/images/speakers/kamalova-ilmira.png"
import IslamovaImage from "../assets/images/speakers/islamova-gulnaz.png"

export const SpeakersData = [
  {
    portrait: MelnikovImage,
    name: "Мельников Юрий Михайлович",
    post: "Первый заместитель министра семьи, труда и социальной защиты",
  }, {
    portrait: EfimovImage,
    name: "Ефимов Михаил Александрович",
    post: "Руководитель направления Управления персоналом компании Тензор",
  }, {
    portrait: AkhyamovImage,
    name: "Ахьямов Ридан Рафикович",
    post: "Директор Республиканского центра занятости населения",
  }, {
    portrait: KamalovaImage,
    name: "Камалова Ильмира Ильясовна",
    post: "Эксперт по разработке и внедрению HR-стратегий в компании. ЦВБР",
  }, {
    portrait: KuklinImage,
    name: "Куклин Александр Сергеевич",
    post: "Эксперт по повышению производительности в компаниях, специалист по коммуникациям и переговорам",
  }, {
    portrait: IslamovaImage,
    name: "Исламова Гульназ Тимерхановна",
    post: "Эксперт по оптимизации структуры компании через процессы систематизации",
  },
]