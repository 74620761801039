import React from 'react';

import styles from "./PartnerCard.module.scss"

import {motion} from "framer-motion"

interface PartnerCardProps {
  logo: any;
  link: string;
}

const PartnerCard = ({logo, link}: PartnerCardProps) => {
  return (
    <motion.div className={styles.partnerCard} onClick={() => window.location.href = link} initial="inactive"
                whileInView="active" transition={{duration: .3}}
                whileHover={{scale: 0.9}}
                variants={{
                  inactive: {scale: 0.8},
                  active: {scale: 1},
                }}>
      <img src={logo} alt="логотип" className={styles.logo}/>
    </motion.div>
  );
};

export default PartnerCard;