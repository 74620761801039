import IslamovaImage from "../assets/images/speakers/islamova-gulnaz.png"
import RegisterIcon from "../assets/images/icons/register.svg"
import MelnikovImage from "../assets/images/speakers/melnikov-yuriy.png"
import AkhyamovImage from "../assets/images/speakers/akhyamov-ridan.jfif"
import KamalovaImage from "../assets/images/speakers/kamalova-ilmira.png"
import CoffeeBreakImage from "../assets/images/icons/break-cafe.svg"
import KuklinImage from "../assets/images/speakers/kuklin-alex.png"
import EfimovImage from "../assets/images/speakers/efimov-mikhail.png"
import CheckIcon from "../assets/images/icons/check.svg"

export const ProgramContent = [
  {
    title: "Регистрация участников форума",
    text: "",
    time: "10:20-10:50",
    name: "Чек-ин и проверка участников",
    portrait: RegisterIcon
  }, {
    title: "Вводное слово модератора",
    text: "",
    time: "10:50-11:00",
    name: "Приветствие участников и гостей форума",
    portrait: MelnikovImage
  }, {
    title: "Приветственное слово",
    text: "Первый заместитель министра семьи, труда и социальной защиты",
    time: "11:00-11:20",
    name: "Мельников Юрий Михайлович",
    portrait: MelnikovImage
  }, {
    title: "Роль HR в современных компаниях",
    text: "Директор Республиканского центра занятости населения",
    time: "11:20-11:50",
    name: "Ахьямов Ридан Рафикович",
    portrait: AkhyamovImage
  }, {
    title: "Выстраивание HR-стратегий в компаниях",
    text: "Эксперт по разработке и внедрению HR-стратегий. ЦВБР",
    time: "11:50-12:30",
    name: "Камалова Ильмира Ильясовна",
    portrait: KamalovaImage
  }, {
    title: "Кофе-брейк, фотозона",
    text: "На локации будет фотозона, в которой можно сделать красивые фото на память",
    time: "12:30-13:00",
    name: "30 минутный перерыв",
    portrait: CoffeeBreakImage
  }, {
    title: "Как сохранить и повысить производительность компании без найма нового персонала",
    text: "Эксперт по повышению производительности в компаниях, специалист по коммуникациям и переговорам",
    time: "13:00-13:30",
    name: "Куклин Александр Сергеевич",
    portrait: KuklinImage
  }, {
    title: "Почему SABY КЭДО самое функциональное решение на рынке?",
    text: "Руководитель направления Управления персоналом компании Тензор",
    time: "13:30-14:30",
    name: "Ефимов Михаил Александрович",
    portrait: EfimovImage
  }, {
    title: "Выстраивание HR-процессов с помощью внедрения SABY КЭДО в компании «Баракат»",
    text: "Эксперт по оптимизации структуры компании через процессы систематизации",
    time: "14:30-15:00",
    name: "Исламова Гульназ Тимерхановна",
    portrait: IslamovaImage
  }, {
    title: "Подведение итогов форума",
    text: "",
    time: "15:00-15:15",
    name: "Подведение итогов форума",
    portrait: CheckIcon
  },
]