import React, {useRef} from 'react';

import styles from "./AccordionItem.module.scss"

import PlusIcon from "../../../../assets/images/icons/bottomArrow.svg"
import {motion} from "framer-motion"

interface AccordionItemProps {
  title: string;
  text: string;
  name: string;
  time: string;
  isOpen: boolean;
  onClick: any;
}

const AccordionItem = ({title, text, name, time, isOpen, onClick}: AccordionItemProps) => {
  const itemRef = useRef(null);

  return (
    <motion.li className={styles.accordionItem} initial="inactive" whileInView="active" transition={{duration: .5}}
               variants={{
                 inactive: {opacity: 0, scale: 0},
                 active: {opacity: 1, scale: 1},
               }}>
      <button className={isOpen ? styles.accordionHeader + " " + styles.opened : styles.accordionHeader}
              onClick={() => onClick()}>
        <h3 className={styles.time}>{time}</h3>
        {title}
        <img src={PlusIcon} alt="Стрелка"
             className={isOpen ? styles.accordionArrow + " " + styles.activeArrow : styles.accordionArrow}/>
      </button>
      <div
        className={isOpen ? styles.accordionCollapse + " " + styles.open : styles.accordionCollapse}
        style={
          // @ts-ignore
          isOpen ? {height: itemRef.current.scrollHeight} : {height: "0px"}
        }
      >
        <div className={styles.accordionBody} ref={itemRef}>
          <div className={styles.speakerCard}>
            <div className={styles.textWrapper}>
              <h4 className={styles.name}>{name}</h4>
              <p className={styles.post}>{text}</p>
            </div>
          </div>
        </div>
      </div>
    </motion.li>
  );
};

export default AccordionItem;