import React from 'react';
import Accordion from "../ui/Accordion/Accordion";
import RegButton from "../ui/RegButton/RegButton";
import SectionTitle from "../ui/SectionTitle/SectionTitle";

import styles from "./Program.module.scss"
import {ProgramContent} from "../../lib/ProgramContent";


const Program = () => {
  return (
    <div className={styles.program}>
      <SectionTitle title={"Программа"}/>

      <div className={styles.columns}>
        <div className={styles.accColumn}>
          <Accordion accordionItems={ProgramContent}/>
        </div>

      </div>
      <RegButton className={styles.regBtn}/>
    </div>
  );
};

export default Program;