export const NavItems = [
  {
    linkTo: "#program",
    text: "Программа"
  },
  {
    linkTo: "#speakers",
    text: "Спикеры"
  },
  {
    linkTo: "#partners",
    text: "Партнеры"
  },
  {
    linkTo: "https://online.sbis.ru/event/HR-forum",
    text: "Регистрация"
  },
]