import React from 'react';

import styles from "./StartSectionLayout.module.scss"

interface StartSectionLayoutProps {
  children: React.ReactNode
  id?: string;
}

const StartSectionLayout = ({children, id}: StartSectionLayoutProps) => {
  return (
    <section className={styles.startSectionLayout} id={id && id}>
      <div className={styles.container}>
        {children}
      </div>
    </section>
  );
};

export default StartSectionLayout;