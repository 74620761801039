import React from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay} from "swiper/modules"
import useDimensions from "../../hooks/useDimensions";

import SectionTitle from "../ui/SectionTitle/SectionTitle";
import SpeakerCard from "../ui/SpeakerCard/SpeakerCard";


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay'

import styles from "./Speakers.module.scss"

import "./speakers.scss"

interface SpeakersProps {
  speakers: {
    portrait: any;
    name: string;
    post: string;
  }[]
}

const Speakers = ({speakers}: SpeakersProps) => {
  const windowWidth = useDimensions().width;

  const setSlidesPerView = () => {


    if (windowWidth < 430) {
      return 1
    } else if (windowWidth < 580) {
      return 1.5
    } else if (windowWidth < 690) {
      return 2
    } else if (windowWidth < 1050) {
      return 2.5
    } else {
      return 3.5
    }
  }

  const isCenteredSlides = () => {
    if (windowWidth < 430) {
      return false
    } else if (windowWidth < 580) {
      return true
    } else if (windowWidth < 690) {
      return false
    } else if (windowWidth < 1050) {
      return true
    }

    return true
  }


  return (
    <div className={styles.speakers}>
      <SectionTitle title="Спикеры"/>

      <Swiper
        slidesPerView={setSlidesPerView()}
        spaceBetween={30}
        className={styles.swiper}
        modules={[Autoplay]}
        centeredSlides={isCenteredSlides()}
        autoplay={{
          delay: 0,
          pauseOnMouseEnter: true,
        }}

        speed={5000}
        loop={true}
      >
        <SwiperSlide><SpeakerCard speaker={speakers[0]}/></SwiperSlide>
        <SwiperSlide><SpeakerCard speaker={speakers[1]}/></SwiperSlide>
        <SwiperSlide><SpeakerCard speaker={speakers[2]}/></SwiperSlide>
        <SwiperSlide><SpeakerCard speaker={speakers[3]}/></SwiperSlide>
        <SwiperSlide><SpeakerCard speaker={speakers[4]}/></SwiperSlide>
        <SwiperSlide><SpeakerCard speaker={speakers[5]}/></SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Speakers;