import React from 'react';

import styles from "./SectionLayout.module.scss"

interface SectionLayoutProps {
  children: React.ReactNode;
  id?: string;
}

const SectionLayout = ({children, id}: SectionLayoutProps) => {
  return (
    <section className={styles.sectionLayout} id={id && id}>
      <div className={styles.container}>
        {children}
      </div>
    </section>
  );
};

export default SectionLayout;