import React from 'react';

import styles from "./SpeakerCard.module.scss"


interface SpeakerCardProps {
  speaker: {
    portrait: any;
    name: string;
    post: string;
  }
}

const SpeakerCard = ({speaker}: SpeakerCardProps) => {
  return (
    <div className={styles.speakerCard}>
      <div className={styles.content}>
        <img src={speaker.portrait} alt="портрет" className={styles.portrait}/>

        <div className={styles.textWrapper}>
          <h3 className={styles.name}>{speaker.name}</h3>
          <p className={styles.post}>{speaker.post}</p>
        </div>
      </div>
    </div>
  );
};

export default SpeakerCard;