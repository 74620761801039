import React, {useState} from 'react';
import styles from "./Accordion.module.scss"
import AccordionItem from "./AccordionItem/AccordionItem";

interface AccordionProps {
  accordionItems: {
    title: string;
    text: string;
    name: string;
    time: string;
  }[]
}

const Accordion = ({accordionItems}: AccordionProps) => {
  const [openId, setId] = useState<number | null>(null);

  return (
    <div className={styles.accordion}>
      <ul>
        {accordionItems.map((item, id) => (
          <AccordionItem
            onClick={() => (id === openId ? setId(null) : setId(id))}
            key={id}
            isOpen={id === openId}
            title={item.title}
            text={item.text}
            time={item.time}
            name={item.name}
          />
        ))}
      </ul>
    </div>
  );
};

export default Accordion;