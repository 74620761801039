import React from 'react';

import styles from "./SectionTitle.module.scss"

interface SectionTitleProps {
  title: string;
  className?: string;
}

const SectionTitle = ({title, className}: SectionTitleProps) => {
  return (
    <h1 className={className ? styles.sectionTitle + " " + className : styles.sectionTitle}>{title}</h1>
  );
};

export default SectionTitle;